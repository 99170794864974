html,
body {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f7f8fa;
}

input, button {
  font-family: 'Poppins', sans-serif;
}
